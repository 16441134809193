<template>
  <div>
    <!-- Botón para mostrar/ocultar el sidebar -->
    <!--<button class="toggle-button float-right" @click="toggleSidebar">Menú</button>-->
    <div :class="['sidebar p0 menu-container', { 'active': vista }]">
      <!-- Contenido del sidebar -->
      <ul v-if="isSidebarVisible" class="menu">
        <!-- Lista de elementos del menú -->
        <li v-for="(Menu,i) in Menus" :key="Menu.idmenuP" @click.stop="toggleSubmenu(i)">
          <div :class="['alignText',{'menuSelected': i === showSubmenu}]">
            <font-awesome-icon :icon="Menu.icon" class="menu-icon ml-1"/> <span class="menu-text">{{ Menu.nombreMenuP }}</span>  
            <i :class="['fas', {'fa-chevron-down': i === showSubmenu, 'fa-chevron-left': i !== showSubmenu},'float-right']"></i>
          </div>
          <transition name="fade">
            <ul v-if="Menu.submenu" :class="{'submenu submenu-open':abierto == i, 'submenu submenu-closed':abierto != i || showSubmenu == null}" v-show="showSubmenu === i">

              <li v-for="(submenu, j) in Menu.submenu" :key="submenu.idMenu" @click="toggleSubmenuItems(j)"  @click.stop>

                <div v-if="submenu.items.length <= 0">  
                  <router-link :to="submenu.pathMenu"  class="sidebar-link">
                    <font-awesome-icon :icon="['fas', 'circle-minus']" class="ml-2" style="vertical-align: middle;"/> <span style="margin-left: 2px;">{{submenu.nombreMenu}}</span>
                  </router-link>
                </div>
                <div v-else class="sidebar-link menuItem">
                  <div class="row">  
                    <div class="col col-10">
                      <font-awesome-icon :icon="['fas', 'circle-minus']" class="float-left ml-2" style="vertical-align: middle; margin-top: 1.8px;"/> <div class="mt-auto" style="margin-left:25px;">{{submenu.nombreMenu}}</div>
                    </div>
                    <div class="col col-2">
                     <font-awesome-icon icon="caret-down" class="float-right mr-2"/>  
                   </div>
                 </div>
               </div>

               <transition name="slide-fade">
                 <div v-if="abiertoI === j">
                  <ul v-for="item in submenu.items" :key="item.idMenu" @click.stop>
                    <router-link :to="item.pathMenu" class="items-link">
                      <span class="ml-4"><font-awesome-icon :icon="['far', 'circle']"/> <span class="ml-1">{{item.nombreMenu}}</span></span>
                    </router-link>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
        </transition>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name:"componenteMenus",
    data() {
      return {
        isSidebarVisible: true,
        showSubmenu: true,
        showSubmenuItems: true,
        abierto: '',
        cerrado: '',
        abiertoI: '',
        cerradoI: '',
        Menus: [],
      };
    },
    methods: {
      evitarDireccion(submenu, event) {
        if (submenu.items.length > 0) {
          event.preventDefault();
        }
      },
      toggleSubmenu(index) {
        if (this.showSubmenu === index) {
          this.showSubmenu = null;
          this.abierto = '';
        } else {
          this.showSubmenu = index;
          this.abierto = index;
        }
      },
      toggleSubmenuItems(j){
        if(this.abiertoI === j){
          this.abiertoI = '';
        }else{
          this.abiertoI = j;
        }
      },
    // Método para verificar si un menú tiene submenús
      hasSubmenus(menu){
        return menu.items && menu.items.length > 0;
      },
      devolverMenusUsuario(usuario){
        this.axios.get(`devolverMenus/${usuario}`)  
        .then(data=>{
          this.Menus = data.data;
        }).catch(error => {
          this.$toasted.error('Ocurrió un error'+ error);
        });
      },
    },
    computed:{
      ...mapState({
        visible: state => state.menu,
        vista(){
          return this.visible;
        }
      }),
      ...mapState(['usuario']),
    },
    mounted: function(){
      this.devolverMenusUsuario(this.usuario);
    }
  };
</script>
<style>
  .sidebar {
    top: 67px;
    width: 243px;
    height: calc(100vh - 69px);
    background-color: #274054;
    transition: all 0.6s ease-in;
    transform: translateX(-100%);
    position: fixed;
    z-index: 100;
    max-width: 255px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 60px;
  }

  /*editamos el ancho del scrollbar del sidebar*/
  .sidebar::-webkit-scrollbar {
    width: 5px;
  }

  /*le damos color a la barra del scrollbar y redondeamos el borde*/
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #687482;
    border-radius: 6px; 
  }

  .sidebar::-webkit-scrollbar-track {
    background-color: #23394B;
  }

  .active {
    transform: translateX(0);
  }

  .menu{
    padding: 0;
    min-width: 250px;
    max-width: 250px;
    font-size: 14px;
    margin-left:-15px;
    max-height: 1000px;
    transition: max-height 0s ease-in;
    cursor: pointer;
    z-index: 50;
  }


  .menu-icon {
    margin-right: 10px;
    min-width: 20px;
    text-align: center;
  }

  .menu-text {
    flex: 5;
  }

  .menu.active {
    max-height: 800px;
  }

  .menu li {
    text-align: left;
    margin-left: 0px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }


  .menu li a {
    text-decoration: none;
    color: #cccfc5;
    font-family: helvetica;
  }

  .menu li .menuItem {
    text-decoration: none;
    color: #cccfc5;
    font-family: helvetica;
  }


  .menu li .menuItem:hover {
    text-decoration: none;
    color: #fff;
    font-family: helvetica;
  }

  .menu-item {
    display: flex;
    align-items: center;
  }


  .menuItem:hover{
    color: #fff;
  }

  .menu li a.selected i {
    transform: rotate(90deg);
  }

  .submenu {
    font-family: sans-serif;
    list-style: none;
    max-height: 1000px;
    opacity: 1;
    padding: 0;
    margin-top:10px;
    margin-bottom: -10px;
    margin-left: 20px;
    font-size: 12px;
    z-index: 20;
    position: relative;
  }


  .submenuClose {
    list-style: none;
    opacity: 1;
    padding: 0;
    margin-top:20px;
    margin-bottom:-20px;
  }


  .submenu li {
    border:0;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
    max-resolution: 20px;
    z-index: 10;
  }
  .submenu .sidebar-link:hover{
    background-color: #425E79;
  }

  .submenu-open {
    animation: slideDown 1s ease-in-out;
  }

  .submenu-closed {
    animation: slideUp 0.4s ease-in-out;
  }

  .submenu {
    transition: max-height 0s ease-in-out; 
  }

  .submenu-closed {
    max-height: 21px; 
  }

  .sidebar-link {
    border: 0;
    margin-top: 0px;
    margin-left: -30px;
    padding: 9px;
    display: block;
    width: 257px;
    height: max-heigh;
    text-decoration: none;
    color: inherit;
    background-color: #3d5770;
    border-bottom: 0.1px solid #637E97;
  }

  .items-link {
    font-family: inherit;
    border: 0;
    margin-top: 0px;
    margin-left: -70px;
    padding: 11px;
    display: block;
    width: 257px;
    height: auto;
    text-decoration: none;
    color: inherit;
    background-color: #374A5D;
    font-size: 11px;
    display: flex;
    border-bottom: 0.1px dashed #637E97;
  }

  .items-link:hover{
    background-color: #425E79;
  }

  .sidebar-link:hover{
    color: #fff;
  }

  .align{
    margin-right: 60px;
  }
  .alignText{
    margin-left: 7px;
    color: #E8E8E8;
  }


  .fade-enter-active {
    transition: opacity 1s;
  }

  .fade-leave-active {
    transition: opacity 0s ease-in;
  }


  .fade-leave-to{
    opacity: 0;
  }

  .fade-enter{
    opacity: 0;
  }


  .arrow-down {
    width: 1.5px;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    margin-left: 10px;
    display: inline-block;
  }


  .arrow-down.rotate,
  .arrow-right.rotate {
    transform: rotate(90deg);
  }

  @keyframes slideDown {
    from {
      max-height: 21px;
      opacity: 0;
    }to{
      max-height: 800px;
      opacity: 1;
    }
  }


  @keyframes slideUp {
    from {
      max-height: 800px; 
      opacity: 1;
    }to{
      max-height: 21px;
      opacity: 0;
    }
  }

  .config{
    align-content: center;
    text-align: center;
  }

  .config:hover{
    background-color: #3d5770;
    border-radius: 100px;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all 0.3s ease;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
</style>
